/** @format */

import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Card from "../components/Card";
import Footer from "../components/Footer";
// import Carousal from "../components/Carousal";

function Home() {
  const [foodCat, setFoodCat] = useState([]);
  // eslint-disable-next-line
  const [foodItem, setFooditem] = useState([]);
  const [search, setSearch] = useState('')

  const loaddata = async () => {
    let response = await fetch("http://localhost:3100/api/foodData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = await response.json();
    setFooditem(response[0]);
    setFoodCat(response[1]);
    // console.log(response[0], response[1]);
  };

  useEffect(() => {
    loaddata();
  }, []);

  return (
    <div className="bg-dark">
      <div>
        <Navbar />
      </div>

      <div>
      <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        style ={{objectFit:"contain !important"}}
      >
        <div className="carousel-inner" id='carousel'>
          <div className="carousel-caption " style={{zIndex:"5"}}>
            <div className="d-flex justify-content-center" role="search" >
              <input
                className="form-control me-2 text-white bg-dark"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value= {search}
                onChange ={(e)=>{setSearch(e.target.value)}}
              />
              {/* <button className="btn btn-outline-success text-white bg-success" type="submit">
                Search
              </button> */}
            </div>
          </div>

          <div className="carousel-item active">
            <img
              src="https://cdn.britannica.com/08/177308-050-94D9D6BE/Food-Pizza-Basil-Tomato.jpg"
              className="d-block w-100"
              alt="..."
            //   height="800px"
            //   width=" 800px"
              style={{filter : "brightness(50%)"}}
            />
          </div>
          <div className="carousel-item">
            <img
              src="https://www.cookforindia.com/wp-content/uploads/2016/08/Paneer-Tikka-_LR.jpg"
              className="d-block w-100"
              alt="..."
            //   height="800px"
            //   width=" 800px"
              style={{filter : "brightness(50%)"}}
            />
          </div>
          <div className="carousel-item">
            <img
              src="https://c.ndtvimg.com/2022-04/fq5cs53_biryani-doubletree-by-hilton_625x300_12_April_22.jpg"
              className="d-block w-100"
              alt="..."
            //   height="800px"
            //   width=" 800px"
              style={{filter : "brightness(50%)"}}
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>

      <div className="container text-white  m-3  ">
        {foodCat !== [] ? (
          foodCat.map((data) => {
            return (
              <div className="row mb-3">
            <div className="fs-4 m-3" key={data._id}>{data.CategoryName}</div>
            <hr />
             {
            foodItem !== [] ?  
             foodItem.filter((item)=>(item.CategoryName ===data.CategoryName)  && (item.name.toLowerCase().includes(search.toLocaleLowerCase())))
               
               
               .map((filterItems)=>{
               return(
                 <div key={filterItems._id} className="col-12 col-md-6 col-lg-3">
                   {/* <Card foodName={filteritem.name}
                   options = {filteritem.options[0]} 
                  imgSrc ={filteritem.img} /> */}
                   <Card foodItem = {filterItems}
                   
                   options = {filterItems.options[0]} 

                   ></Card>
                 </div>
               )}
               )
               // filter logic
            
            : <div>""""</div> // fooditem ternary operator
             
          }{/* fooditem logic */}
                </div> // parent div of return
            )
            // ternary oper :
          })
        ) : (
          <div>"""""</div>
        )}
      </div>

      <div>
        {" "}
        <Footer />{" "}
      </div>
    </div>
  );
}

export default Home;
