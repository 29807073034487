/** @format */

import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
function Signup() {

  const navigate = useNavigate()

  const [credientials, setCredientials] = useState({
    name: "",
    email: "",
    password: "",
    location: ""
  });
  const handleSubmit = async (e) => {
      e.preventDefault();
      const  {name,email,password,location} = credientials
    
    const response = await fetch("http://localhost:3100/api/createuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({name,email,password,location })
    });

    const json = await response.json();
    console.log(json);
    if (!json.success) {
                      
      alert("Enter valid credientials");
    }
    if(json.success){
      localStorage.setItem('authtoken',json.authtoken)
      localStorage.setItem('userEmail',email)
      console.log(json.authtoken)
      navigate('/')
   
}
  }
  
  const onChange = (event) => {
    setCredientials({
      ...credientials,
      [event.target.name]: event.target.value
    });
  };
  return (
    <div className="container mt-3 fw-bolder bg-dark">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label text-white" >
            Name
          </label>
          <input
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            id="name"
            name="name"
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label text-white" >
            Email
          </label>
          <input
            type="email"
            className="form-control"
            aria-describedby="emailHelp"
            id="email"
            name="email"
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="password"
            className="form-label text-white"
          >
            Password
          </label>
          <input
            type="password"
            className="form-control"
            name ="password"
            id="password"
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="location"
            className="form-label text-white"
          >
            Address
          </label>
          <input
            type="text"
            className="form-control"
            id="location"
            name="location"
            onChange={onChange}
          />
        </div>

        <button type="submit" className="m-3 btn btn-success">
          SignUp
        </button>
        
        <Link to="/login" className="m-3 btn btn-danger">
          Already a user
        </Link>
      </form>
    </div>
  );
}

export default Signup;
