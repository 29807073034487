import React from 'react'
// import Delete from '@material-ui/icons/Delete'
import { useCart, useDispatchCart } from '../components/ContextReducer';
// import Trash from "/trash(1).svg"
import Pay from '../components/Pay'
// import Paybutton from './Paybutton'


export default function Cart() {
  let data = useCart();
  let dispatch = useDispatchCart();
  
  // const handleRemove = (index)=>{
    //   console.log(index)
    //   dispatch({type:"REMOVE.",index:index})
    // }
    
    if (data.length === 0) {
      return (
        <div>
          <div className='m-5 w-100 text-center text-white fs-3'>Your cart is empty</div>
        </div>
      )
    }
  const handleCheckOut = async () => {
       let userEmail = localStorage.getItem("userEmail");
      // console.log(data,localStorage.getItem("userEmail"),new Date())
      // console.log(userEmail)
    let response = await fetch("http://localhost:3100/api/orderData", {
      // credentials: 'include',
      // Origin:"http://localhost:3000/login",
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          order_data: data,
          email: userEmail,
          order_date: new Date().toDateString()
        })
    });
    const json = await response.json()
    console.log("JSON RESPONSE:", response.status)
    // if (response.status === 200) {
    //     dispatch({ type: "DROP" })
    // }
    console.log(json)
}

let totalPrice = data.reduce((total, food) => total + food.price, 0)
return (
    <div className="text-success">

      {/* {console.log(data)} */}
      <div className='container m-auto mt-5 table-responsive  table-responsive-sm table-responsive-md text-white' >
        <table className='table table-hover '>
          <thead className=' text-success fs-4'>
            <tr>
              <th scope='col' >#</th>
              <th scope='col' >Name</th>
              <th scope='col' >Quantity</th>
              <th scope='col' >Option</th>
              <th scope='col' >Amount</th>
              <th scope='col' ></th>
            </tr>
          </thead>
          <tbody className="text-white">
            {data.map((food, index) => (
              <tr >
                <th scope='row' >{index + 1}</th>
                <td >{food.name}</td>
                <td>{food.qty}</td>
                <td>{food.size}</td>
                <td>{food.price}</td>
                <td ><button type="button" className="btn p-0 text-white" onClick={() => { dispatch({ type: "REMOVE", index: index }) }} > Delete </button> </td>
                </tr>
            ))}
            {/* */}
          </tbody>
        </table>
        <div><h1 className='fs-2'>Total Price: {totalPrice}/-</h1></div>
        <div className="d-flex ">
          <button className='btn bg-success mt-5 ' onClick={handleCheckOut} > Check Out </button>
         
          <Pay />
        </div>
      </div>



    </div>
)
}