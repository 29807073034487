import React from 'react'
// import { useCart } from '../components/ContextReducer';
import { useCart, useDispatchCart } from '../components/ContextReducer';

const Pay = () => {
  let dispatch = useDispatchCart();

    let data1 = useCart();
  let totalPrice = data1.reduce((total, food) => total + food.price, 0)
    console.log(totalPrice)
    const checkOuthandler = async()=>{
     await fetch("http://localhost:3100/api/pay", {
            credentials: 'include',
            Origin:"http://localhost:3000",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
            amount : totalPrice
            }),
          }).then(async (res) => {
            let response = await res.json();
            // console.log(response.data)
          let  data = response.data
            console.log(data)
            handlePayment(data)
          }).catch(e =>{
              console.log(e)
          })
         
    }
    const handlePayment = async(data)=>{
        const options ={
            key : 'rzp_test_Y1OV3eKH7LaSSh',
            amount :Number(data.amount*100),
            currency : data.currency,
            order_id : data.id,
            name : "GrandDragon Restaurent",
            handler: function(response){
                // alert("payment  successfull")
                console.log(response )
                // console.log(response.razorpay_order_id)
                


                 fetch("http://localhost:3100/api/paymentverification", {
                    credentials: 'include',
                    Origin:"http://localhost:3000",
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        response : response
                    }),
                  }).then(async (res) => {
                    console.log(res)
                            dispatch({ type: "DROP" })

                  }).catch(e =>{
                      console.log(e)
                  })
            }
        }
        const rzp = new  window.Razorpay(options)
        rzp.open()
    }
    
    return (
        <div>
            <button className="btn bg-success mt-5 mx-2 text-white" onClick={checkOuthandler} >Pay Now</button>
        </div>
    )
}

export default Pay
