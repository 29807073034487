import React,{useState} from 'react'
import {Link,useNavigate} from 'react-router-dom'
function Login() {
    const [credientials, setCredientials] = useState({
        email: "",
        password: ""
      });
      let navigate = useNavigate()
      const handleSubmit = async (e) => {
          e.preventDefault();
          const  {email,password} = credientials
        
        const response = await fetch("http://localhost:3100/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({email,password})
        });    const json = await response.json();
        console.log(json);
        if (!json.success) {
            alert("Enter valid credientials");
        }
        if (json.success) {
            // localStorage.setItem("userEmail",email)
            localStorage.setItem("authtoken",json.authtoken)
            console.log("authtoken :",json.authtoken)
          navigate("/")
        }
      };
      const onChange = (event) => {
        setCredientials({
          ...credientials,
          [event.target.name]: event.target.value
        });
      };
    return (
        <div className="container mt-3 fw-bolder">
        <form onSubmit={handleSubmit}>
         
          <div className="mb-3">
            <label htmlFor="email" className="form-label text-white" >
              Email
            </label>
            <input
              type="email"
              className="form-control"
              aria-describedby="emailHelp"
              id="email"
              name="email"
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="password"
              className="form-label text-white"
            >
              Password
            </label>
            <input
              type="password"
              className="form-control"
              name ="password"
              id="password"
              onChange={onChange}
            />
          </div>
         
  
          <button type="submit" className="m-3 btn btn-success" >
            Login
          </button>
          
          <Link to="/createuser" className="m-3 btn btn-danger">
            New user
          </Link>
        </form>
      </div>
    )
}

export default Login
