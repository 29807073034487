import './App.css';
import Home from "./pages/Home";
// import Footer from "./pages/Footer";
import {Route,Routes} from 'react-router-dom'
import Login from './pages/Login';
import Signup from './pages/Signup';
// eslint-disable-next-lin
import '../node_modules/bootstrap-dark-5/dist/css/bootstrap-dark.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import {CartProvider} from './components/ContextReducer'
import MyOrder from './pages/MyOrder'
import Cart from './pages/Cart'
function App() {
  return (
    <CartProvider>
    <div>
      <Routes>
       <Route path ="/" element={<Home />}></Route>
       <Route path ="/login" element={<Login />}></Route>
       <Route path ="/createuser" element={<Signup />}></Route>
       <Route path ="/myOrder" element={<MyOrder />}></Route>
       <Route path ="/cart" element={<Cart />}></Route>

      </Routes>
    
    </div>
    </CartProvider>
  );
}

export default App;

