/** @format */

import React,{useState} from "react";
import { Link,useNavigate} from "react-router-dom";
import Image from '../images/sd.jfif'
import Modal from '../Modal';
import Cart from '../pages/Cart';
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from 'react-bootstrap/Badge'
import { useCart, useDispatchCart } from '../components/ContextReducer';


export default function Navbar(){
const navigate = useNavigate()
const [cartView,setCartView] = useState(false)
 const handleLogout = ()=>{
 localStorage.removeItem("authToken");
 navigate("/login")
}
let data = useCart();


  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-success ">
        <div className="container-fluid">
          {/* eslint-disable-next-line  */}
          <img src={Image} alt="An image" style={{ width: "50px" }} />
          <Link className="navbar-brand fs-1 fst-italic mx-4" to="/">
            The Grand Dragon
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav me-auto mb-2">
              <li className="nav-item">
                <Link
                  className="nav-link active fs-4 mt-3"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              {(localStorage.getItem("authtoken")) ?
              <li className="nav-item">
                <Link
                  className="nav-link active fs-4 mt-3"
                  aria-current="page"
                  to="/myOrder"
                  >
                  My Orders
                </Link>
              </li>
              : ""}
              
              </ul>
              {(!localStorage.getItem("authtoken")) ?
              <div className="d-flex">
           <Link className="btn bg-white text-success mx-1 fs-4" to="/login">
                Login
              </Link>
              <Link className="btn bg-white text-success mx-1 fs-4" to="/createuser">
                Signup
              </Link>

              </div>
                   : 
                   <div>
                   <div className="btn bg-white text-success mx-2 fs-4" onClick={()=>{setCartView(true)}}>
                     My Cart {" "}
                     <Badge pill bg="danger" > {data.length} </Badge>
                   </div>
                   {cartView ? <Modal onClose={()=>setCartView(false)} ><Cart /></Modal> :null}
                   <div className="btn bg-white text-danger mx-2 fs-4" onClick={handleLogout}>
                    Logout
                   </div>
                   </div>
                   }
              
              

             
            
          </div>
        </div>
      </nav>
    </div>
  );
}

// export default Navbar;
